<template>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl text-center">
      <div class="py-2 px-2">
        <button class="btn btn-success btn-sm w-200px">Pengajuan KTP</button>
      </div>
      <div class="py-2 px-2">
        <button class="btn btn-success btn-sm w-200px">Pengajuan KTAK</button>
      </div>
      <div class="py-2 px-2">
        <button class="btn btn-success btn-sm w-200px">Data Personalia</button>
      </div>
      <div class="py-2 px-2">
        <button class="btn btn-success btn-sm w-200px">Laporan</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
